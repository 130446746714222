<template>
  <div>
    <div :class="temptype" class="news_list">
      <div v-for="(item,index) in articlesData" :key="index" @click="goArticle(item.id)"
           :style="{backgroundImage: 'url('+item.cover_image+')'}" class="news_info">
        <div class="formcategory">{{ item.articles_category_name }}</div>
        <div class="title">{{ item.title }}</div>
        <div class="times">{{ item.created_at | dateFormat('yyyy-M-d HH:mm') }}</div>
        <div class="mask"></div>
      </div>
    </div>
    <div class="mores">
      <a-button class="btn-red" shape="round" size="large" type="danger" @click="gotocategory(articles_category_id,articlesData[0].p_id)">
 <span v-if="language ==='cn'">
                              {{ dictionary.cn.Readmore}}
                            </span>
        <span v-if="language ==='en'">
                              {{ dictionary.en.Readmore}}
                            </span>
        <span v-if="language ==='fr'">
                              {{ dictionary.fr.Readmore}}
                            </span>
<!--        {{ dictionary.cn.Readmore}}-->
        <a-icon type="arrow-down"/>
      </a-button>
    </div>
  </div>
</template>

<script>
import Config from "@/config";

export default {
  name: "article_list",
  props: ['flextype', 'category', 'newslimit', 'getall', 'showall', 'language'],
  data() {
    return {
      articles_category_id: this.category,
      dictionary: Config.data.dictionary,
      temptype: this.flextype,
      limit: this.newslimit,
      getalllist: this.getall,
      showalllist: this.showall,
      getlanguage: this.language,
      server_url: Config.data.server_url,
      articlesData: [],
    }
  },
  created() {
    this.getArticleslist(1, this.limit, this.articles_category_id, this.getalllist, this.showalllist)
  },
  methods: {
    async getArticleslist(page, limit, articles_category_id, getall, is_show) {
      console.log("请求id", articles_category_id);
      this.articlesData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/article/list", {
        params: {
          page: page,
          limit: limit,
          articles_category_id: articles_category_id,
          get_all: getall,
          is_show: is_show,
          language: this.language,
        }
      });

      if (result.status === true) {

        this.articlesData = result.data.list;
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    gotocategory(articles_category_id,pid) {
      console.log("goto", this.articles_category_id)
      this.$router.push({
        path: 'category_list',
        query: {
          id: articles_category_id,
          language: this.language,
          key: pid
        }
      });
    },
    goArticle(aid) {
      console.log("goto", aid)
      this.$router.push({
        path: '/article',
        query: {
          articleid: aid,
          language: this.language,
          t: new Date().getTime()
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.news_list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.mores {
  text-align: center;
  width: 100%;
  margin-top: 40px;

}

.btn-red {
  color: #CE1126;
  background-color: unset !important;
  border-color: #CE1126 !important;
}

.news_info {
  background-repeat: no-repeat;
  position: relative;
  background-position: center top;
  background-size: cover;
  min-width: 25%;
  max-width: 25%;
  max-height: 360px;
  min-height: 360px;

  color: #fff;
  border-radius: 8px 8px 8px 8px;

  .formcategory {
    background-color: #002654;
    padding: 6px 16px;
    min-width: 87px;
    font-size: 14px;
    color: #fff;
    min-height: 32px;
    border-radius: 8px 0px 8px 0px;
    display: inline-block;
  }

  .times {
    position: absolute;
    bottom: 16px;
    left: 16px;
    font-weight: bold;
    font-size: 16px;
    z-index: 200;
  }

  .title {
    position: absolute;
    bottom: 42px;
    left: 16px;
    font-weight: bold;
    line-height: 140%;
    font-size: 22px;
    z-index: 200;
  }

  .mask {
    background: linear-gradient(180deg, rgba(0, 37, 83, 0.1) 43%, #002654 100%);
    position: absolute;
    display: block;
    z-index: 100;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 8px 8px;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
}

.flex_a {
  .news_info {
    width: 23% !important;
    min-width: 23% !important;
    max-width: 23% !important;
    margin-right: 2%;

    &:last-child {
      width: 50% !important;
      min-width: 50% !important;
      max-width: 50% !important;
      margin-right: 0 !important;
      background-size: contain !important;
    }
  }

}

.flex_b {
  .news_info {
    width: 31.333% !important;
    min-width: 31.333% !important;
    max-width: 31.333% !important;
    margin-right: 3%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.flex_c {
  .news_info {
    width: 23% !important;
    min-width: 23% !important;
    max-width: 23% !important;
    margin-right: 2.6%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
</style>