<template>
  <div>
   <topslogan/>
    <div class="index_1">
      <topnav :category_id="navbarselected"></topnav>
      <a-carousel ref="varousel" :after-change="onChange" autoplay>
        <!--              :style="{'background-image': `url(${require('')})`}"-->
        <div v-for="(item,index) in Carouseldata" :key="index" :class="'carousel_'+index"
             :style="{backgroundImage: 'url('+server_url+item.cover_max_img+')'}">

          <div class="carousel_info">
            <h3>{{ item.title }}</h3>
            <h4>{{ item.title2 }}</h4>
<!--            <a-button class="btn-danger" shape="round" size="large" type="danger">-->
<!--              <span v-if="language ==='cn'">-->
<!--                    {{ dictionary.cn.Readmore}}-->
<!--                  </span>-->
<!--              <span v-if="language ==='en'">-->
<!--                    {{ dictionary.en.Readmore}}-->
<!--                  </span>-->
<!--              <span v-if="language ==='fr'">-->
<!--                    {{ dictionary.fr.Readmore}}-->
<!--                  </span>-->
<!--              <a-icon type="arrow-right"/>-->
<!--            </a-button>-->
          </div>
        </div>

      </a-carousel>
    </div>
    <div class="index_2">
<!--       <span v-if="language ==='cn'">-->
<!--                   cn-->
<!--      </span>-->
<!--      <span v-if="language ==='en'">-->
<!--                   en-->
<!--      </span>-->
<!--      <span v-if="language ==='fr'">-->
<!--                  fr-->
<!--      </span>-->
      <div class="PMTinfo" v-if="categoryinfo_show">
<!--        {{categoryinfo}}-->
        <div class="infoleft"  v-if="categoryinfo_show">
          <h3>{{categoryinfo.more_info.title}}</h3>
          <div class="info">{{categoryinfo.more_info.content}}</div>
                      <a-button class="btn-danger" shape="round" size="large" type="danger" @click="goArticle(categoryinfo.more_info.url)">
                        <span v-if="language ==='cn'">
                              {{ dictionary.cn.Readmore}}
                            </span>
                        <span v-if="language ==='en'">
                              {{ dictionary.en.Readmore}}
                            </span>
                        <span v-if="language ==='fr'">
                              {{ dictionary.fr.Readmore}}
                            </span>
                        <a-icon type="arrow-right"/>
                      </a-button>
        </div>

        <div class="inforight" :style="{backgroundImage: 'url('+categoryinfo.more_info.cover_img+')'}" v-if="categoryinfo_show">

        </div>
      </div>
      <div class="getCertificate">
        <div class="top"><span></span><span></span></div>
        <h3>
           <span v-if="language ==='cn'">
                              {{ dictionary.cn.Traineecertificatequery}}/ {{ dictionary.cn.Trainercertificatequery}}
                            </span>
          <span v-if="language ==='en'">
                              {{ dictionary.en.Traineecertificatequery}}/ {{ dictionary.en.Trainercertificatequery}}
                            </span>
          <span v-if="language ==='fr'">
                              {{ dictionary.fr.Traineecertificatequery}}/ {{ dictionary.fr.Trainercertificatequery}}
                            </span></h3>
        <div class="Certificate">
          <a-input class="SearchCertificate" placeholder="eg. XYEK20190101" v-model="Certificateno">

          </a-input>
          <a-button class="btn-danger cx" shape="round" size="large" type="danger" @click='searchCertificate' >
             <span v-if="language ==='cn'">
                              {{ dictionary.cn.Inquiry}}
                            </span>
            <span v-if="language ==='en'">
                              {{ dictionary.en.Inquiry}}
                            </span>
            <span v-if="language ==='fr'">
                              {{ dictionary.fr.Inquiry}}
                            </span>
          </a-button>
        </div>
      </div>


    </div>
    <div class="index_3">
      <div class="calendar">
        <h1>
           <span v-if="language ==='cn'">
                              {{ dictionary.cn.Calendar}}
                            </span>
          <span v-if="language ==='en'">
                              {{ dictionary.en.Calendar}}
                            </span>
          <span v-if="language ==='fr'">
                              {{ dictionary.fr.Calendar}}
                            </span>
          </h1>
        <div class="calendar_list">
          <div class="calendar_info">
            <div class="info_left">
              <a-calendar :fullscreen="false" class="calenderdate" @panelChange="onPanelChange" @select="selectday">
            <span slot="dateCellRender" slot-scope="value" class="events">
<!--              {{getType(value)}}-->
              <span v-if="getType(value) !== 'null' ">
                 <a-badge :status="getType(value)"/>
              </span>
            </span>
              </a-calendar>
            </div>

            <!--            {{calendarinfo[0].info.length}}-->
            <div v-if="calendar_show===true" class="info_right">
              <div>
                <!--                <a-result v-if="item.info.length === 0" title="No data">-->

                <!--                </a-result>-->
                <Div class="cardlist2">
                  <h3>{{ calendarinfo.times }}事件</h3>
                  <div v-for="items in calendarinfo.info" :key="items.id" class="cardslist2">
                    <div class="list_title">
                      <div>
                        <a-tag v-if="items.type2 === 1" :color="'#002654'" class="tags">培训</a-tag>
                        <a-tag v-if="items.type2 === 2" :color="'#CE1126'" class="tags">会议</a-tag>
                        <a-tag v-if="items.type2 === 3" :color="'#f50'" class="tags">节日</a-tag>
                        <a-tag v-if="items.type === 2" :color="'blue'" class="tags">每日</a-tag>
                        <a-tag v-if="items.type === 3" :color="'blue'" class="tags">每周</a-tag>
                        <a-tag v-if="items.type === 4" :color="'blue'" class="tags">每月</a-tag>
                        <a-tag v-if="items.type === 5" :color="'blue'" class="tags">每年</a-tag>
                      </div>
                      <div>
                        {{
                          items.content.cn.starttimes| dateFormat('HH:mm')
                        }}-{{ items.content.cn.endtimes| dateFormat('HH:mm') }}
                      </div>
                    </div>

                    <h3 class="title">{{ items.title.cn }}</h3>
                    <!--                    <div> {{ item.content }}</div>-->

                  </div>
                </Div>
              </div>

            </div>

          </div>
          <div class="calendar_info_right">
            <!--            v-for="(item,index) in calendarinfo" :key="index"-->
            <!--            <a-result v-if="item.info.length === 0" title="No data">-->

            <!--            </a-result>-->
            <Div class="cardlist">
              <div class="newslist">
                <news_list :category="52" :flextype="'flex_d'" :getall="1" :language="language" :newslimit="1"
                           :showall="1"></news_list>
              </div>
              <div class="newslist">
                <news_list :category="53" :flextype="'flex_d'" :getall="1" :language="language" :newslimit="1"
                           :showall="1"></news_list>
              </div>


              <!--              <a-card v-for="items in item.info" :key="items.id" :class="item.info.length ===1 ? 'cardsone':'cards'" hoverable>-->
              <!--&lt;!&ndash;                {{item.info.length}}&ndash;&gt;-->
              <!--                <a-tag v-if="items.type2 === 1" :color="'#002654'" class="tags">培训</a-tag>-->
              <!--                <a-tag v-if="items.type2 === 2" :color="'#CE1126'" class="tags">会议</a-tag>-->
              <!--                <a-tag v-if="items.type2 === 3" :color="'#f50'" class="tags">节日</a-tag>-->
              <!--                <a-tag v-if="items.type === 1" :color="'green'" class="tags">当日</a-tag>-->
              <!--                <a-tag v-if="items.type === 2" :color="'blue'" class="tags">每日</a-tag>-->
              <!--                <a-tag v-if="items.type === 3" :color="'blue'" class="tags">每周</a-tag>-->
              <!--                <a-tag v-if="items.type === 4" :color="'blue'" class="tags">每月</a-tag>-->
              <!--                <a-tag v-if="items.type === 5" :color="'blue'" class="tags">每年</a-tag>-->
              <!--                <h2 class="title">{{ items.title.cn }}</h2>-->
              <!--                &lt;!&ndash;                    <div> {{ item.content }}</div>&ndash;&gt;-->
              <!--                <div class="work_mode">-->
              <!--                  <div class="lefttime times">-->
              <!--                    <div>开始时间：</div>-->
              <!--                    <div>{{ items.work_time }}<br/>-->
              <!--                      {{ items.content.cn.starttimes| dateFormat('HH:mm') }}-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="righttime times">-->
              <!--                    <div>-->
              <!--                      <div>结束时间：</div>-->
              <!--                      <div>{{ items.work_time2 }}<br/>-->
              <!--                        {{ items.content.cn.endtimes| dateFormat('HH:mm') }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="address">{{ items.content.cn.localaddress }}</div>-->
              <!--                <div class="button">-->
              <!--                  <a-button shape="round" type="danger">-->
              <!--                    查看详情-->
              <!--                    <a-icon type="arrow-right"/>-->
              <!--                  </a-button>-->
              <!--                </div>-->
              <!--              </a-card>-->
            </Div>

          </div>

        </div>

        <div class="mores">
          <a-button class="btn-white" shape="round" size="large" type="danger" @click="gotocategory(51,51)">
             <span v-if="language ==='cn'">
                              {{ dictionary.cn.Readmore}}
                            </span>
            <span v-if="language ==='en'">
                              {{ dictionary.en.Readmore}}
                            </span>
            <span v-if="language ==='fr'">
                              {{ dictionary.fr.Readmore}}
                            </span>

            <a-icon type="arrow-down"/>
          </a-button>
        </div>
      </div>

    </div>
    <div class="index_4">
      <div class="news">
        <h1>
           <span v-if="language ==='cn'">
                              {{ dictionary.cn.Professors}}
                            </span>
          <span v-if="language ==='en'">
                              {{ dictionary.en.Professors}}
                            </span>
          <span v-if="language ==='fr'">
                              {{ dictionary.fr.Professors}}
                            </span>
          </h1>
        <article_list :category="44" :flextype="'flex_b'" :getall="1" :language="language" :newslimit="3"
                      :showall="1"></article_list>
      </div>
    </div>
    <div class="index_5">
      <div class="news">
        <h1>e-library</h1>
        <article_list :category="31" :flextype="'flex_c'" :getall="1" :language="language" :newslimit="4"
                      :showall="1"></article_list>

      </div>
    </div>
        <div class="index_6" v-show="Cooperation">
          <div class="links">
            <h1><span v-if="language ==='cn'">
                    {{ dictionary.cn.Partner}}
                  </span>
              <span v-if="language ==='en'">
                    {{ dictionary.en.Partner}}
                  </span>
              <span v-if="language ==='fr'">
                    {{ dictionary.fr.Partner}}
                  </span>
            </h1>
            <div class="links_list">
<!--              {{webinfo.Cooperation}}-->
                <div v-for="(item,index) in Cooperation" :key="index" class="sitelinks" :style="{backgroundImage: 'url('+item.cover_image+')'}" @click="goUrl(item.url)"></div>
            </div>
            </div>
        </div>
    <div class="index_7">

          <div class="newsletter">
            <div class="newsletterleft">
              <div><img height="48" src="@/static/images/RSS.png" width="48"/></div>
              <div class="newsletterinfo"> <span v-if="language ==='cn'">
                    {{ dictionary.cn.Subscribetoournewsletter}}
                  </span>
                <span v-if="language ==='en'">
                    {{ dictionary.en.Subscribetoournewsletter}}
                  </span>
                <span v-if="language ==='fr'">
                    {{ dictionary.fr.Subscribetoournewsletter}}
                  </span></div>
            </div>
            <div class="newsletterright">
              <div>
                <a-input placeholder="E-mail" class="Subinputer"/>
              </div>
              <div>
                <a-button class="Subbut">Submit</a-button>
              </div>
            </div>
          </div>
        </div>
    <div class="media_list">
<!--                    {{webinfo}}-->
<!--      {{Cooperation}}-->
      <div v-for="(item,index) in Links" :key="index" class="medialinks" :style="{backgroundImage: 'url('+item.cover_image+')'}"  @click="goUrl(item.url)"></div>
      <div class="medialinks gohome"><a-icon type="arrow-up"/><br/>top</div>
    </div>
    <bottom></bottom>

  </div>

</template>

<script>
import Config from "../config";
import topslogan from "@/views/topslogan";
import topnav from "@/views/topnav";
import bottom from "@/views/bottom";
import article_list from "@/views/article_list";
import news_list from "@/views/news_list";
import moment from "moment";
import Storage from "@/common/storage";

export default {

  components: {topnav,bottom, article_list, news_list,topslogan},
  data() {
    return {
      calendarData: [],
      Carouseldata: [],
      language: localStorage.language,
      // navbar: "",
      // webinfos:"",
      // Links: "",
      // Cooperation: "",
      Certificateno: "",
      server_url: Config.data.server_url,
      dictionary: Config.data.dictionary,
      loading: false,
      nowdata: false,
      calendar_show: false,
      categoryinfo_show: false,
      calendarinfo: [],
      navbarselected: ['0'],
      categoryid: 27,
      categoryinfo: "",
      webinfos: sessionStorage.webinfos,
      Links: sessionStorage.Links,
      Cooperation: sessionStorage.Cooperation,
      navbar: sessionStorage.navbar,
      webinfo: {
        webinfos:"",
        Links:"",
        Cooperation:"",
      },
      // current: ['7'],
    }
  },
  created() {

    // 获取头部菜单内容
    let times = new Date;
    let year = times.getFullYear();
    let month = times.getMonth() + 1;
    let newValue = moment(times).format("YYYY-MM-DD")
    this.nowdata = (year.toString() + "-" + month.toString());

    console.log('now', this.nowdata);
    console.log('localStorage', localStorage);
    console.log('sessionStorage====================', sessionStorage);
    this.language = localStorage.getItem('language')

    // this.navbar = JSON.parse(sessionStorage.getItem('navbar'))
    this.webinfos = JSON.parse(sessionStorage.getItem('webinfos'))
    this.Links = JSON.parse(sessionStorage.getItem('Links'))
    this.Cooperation = JSON.parse(sessionStorage.getItem('Cooperation'))

    console.log("localStorage-index", this.language)
    console.log("webinfos-index", this.webinfos)
    console.log("Links-index", this.Links)
    console.log("Cooperation-index", this.Cooperation)
    console.log("Storage", Storage)
    console.log("uid", Storage.uid)
    console.log("token", Storage.token)
    console.log("login_name", Storage.login_name)
    console.log("is_check_email", Storage.is_check_email)
    Promise.all([this.getTasklist(this.nowdata),this.getcategoryinfo(27), this.getCarousellist(1, 20, "", this.language)]).then(() => {
      this.selectday(newValue)
      this.loading = false;
    }).catch((error) => {
      console.log(error)
    });


    // this.getindex();

    // let mmValue = moment(times).format("YYYY-MM")
    // let year = times.getFullYear();
    // let month = times.getMonth() + 1;
    // this.nowdata = (year.toString() + "-" + month.toString());


  },

  methods: {
    onChange(a) {
      console.log(a);
    },
    goToCarousel(index, item) {
      this.$refs.varousel.goTo(index, false);
      console.log(item)
    },
    getType(value) {
      // console.log("value" ,value);
      let newValue = moment(value).format("YYYY-MM-DD")
      // console.log("value" ,moment(value).format("YYYY-MM-DD"),);
      // console.log("newValue" ,newValue);
      // console.log("newValue" ,this.calendarData[newValue]);
      if (this.calendarData[newValue] !== undefined) {
        if (this.calendarData[newValue].length === 1) {
          return "warning";
        }
        if (this.calendarData[newValue].length === 2) {
          return "success";
        }
        if (this.calendarData[newValue].length >= 3) {
          return "error";
        }


      } else {
        return "null"
      }
    },
    selectday(value, mode) {
      console.log("执行了",value, mode);
      this.nowdata = value
      let newValue = moment(value).format("YYYY-MM-DD")
      let mmValue = moment(value).format("YYYY-MM")
      console.log("newValue", newValue)
      console.log("mmValue", mmValue)
      console.log("calendarData", this.calendarData)

      console.log(moment(Object.keys(this.calendarData)[0]).format("YYYY-MM"))


      if (mmValue === moment(Object.keys(this.calendarData)[0]).format("YYYY-MM")) {
        console.log("月份一致");
        console.log("当前", this.calendarData)
        this.calendarinfo = [];
        // this.calendarinfo = this.calendarData[newValue]
        this.calendarinfo = {
          uid: 0,
          times: newValue,
          info: this.calendarData[newValue]
        }

        console.log("当前", this.calendarinfo);
        if (this.calendarData[newValue].length === 0) {
          console.log("calendar_show==false");
          this.calendar_show = false;
        } else {
          console.log("calendar_show==true");
          this.calendar_show = true;
        }

      } else {
        console.log("月份不一致");
        Promise.all([this.getcalendarlist(mmValue, newValue, "")]).then(() => {
          console.log("当前", this.calendarData)
          this.calendarinfo = [];
          // this.calendarinfo = this.calendarData[newValue]
          this.calendarinfo = {
            uid: 0,
            times: newValue,
            info: this.calendarData[newValue]
          }
          console.log("当前", this.calendarinfo);
          if (this.calendarData[newValue].length === 0) {
            console.log("calendar_show==false");
            this.calendar_show = false;
          } else {
            console.log("calendar_show==true");
            this.calendar_show = true;
          }
        }).catch((error) => {
          console.log(error)
        })

      }

    },
    async onPanelChange(value, mode) {
      console.log("刷新", value);
      console.log("刷新", mode);
      let newValue = moment(value).format("YYYY-MM")
      let newdayValue = moment(value).format("YYYY-MM-DD")
      console.log(newValue);
      if (mode === 'year') {
        this.getcalendarlist(newValue, newdayValue, mode)
      }
    },
    goUrl(url){
      console.log("url", url);
      window.open(url,'_blank');
    },
    goArticle(url) {
      console.log("goto", url)
      this.$router.push({
        path: url,
        query: {
          language: this.language,
         t: new Date().getTime()
        }
      });
    },
    searchCertificate() {

      this.$router.push({
        path: '/searchresults',
        query: {
          Certificateno: this.Certificateno,
           t: new Date().getTime()
        }
      });
    },
    gotocategory(category_id,key) {
      // console.log("goto", this.articles_category_id)
      this.$router.push({
        path: 'category_list',
        query: {
          id: category_id,
          language: this.language,
          key: key,
        }
      });
    },

    async getcalendarlist(year_month, days, mode) {
      console.log("year_month", year_month);
      console.log("mode", mode);
      this.loading = true;
      let result = await this.$get("/api/customer/task/list", {
        params: {
          language: this.language,
          year_month: year_month,
        }
      });

      if (result.status === true) {
        // this.calendarData = JSON.stringify(result.data);
        this.calendarData = [];
        this.calendarData = result.data
        // console.log("sessionStorage",this.calendarData);
        if (mode === 'year') {
          this.calendarinfo = [];
          console.log("模式", "年");
          for (let i = 0; i < Object.keys(result.data).length; i++) {
            console.log(i, Object.keys(result.data)[i]);
            let info = this.calendarData[Object.keys(result.data)[i]];
            this.calendarinfo[i] = {
              uid: i,
              times: Object.keys(result.data)[i],
              info: info
            }
          }
          console.log("模式", this.calendarinfo);
        }

      }

      this.loading = false;
    },
    async getCarousellist(page, limit, type, language) {
      console.log(language);
      this.Carouseldata = []
      this.loading = true;
      let result = await this.$get("/api/customer/systemIndexMoreInfos/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          page: page,
          limit: limit,
          type: type,
          language: language,
        }
      });

      if (result.status === true) {
        console.log(result.data)

        this.Carouseldata = result.data.list;
        if (this.Carouseldata.length > 0) {
          this.goToCarousel(0, this.Carouseldata[0])
        }


        this.loading = false;
      }

      this.loading = false;
    },
    async getcategoryinfo(categoryid) {
      console.log("categoryid->", categoryid)
      this.loading = true;

      let now = Date.now();
      let result = await this.$get("/api/customer/articleCategory/info", {
        params: {
          // token: Storage.token,
          language: this.language,
          articles_category_id: categoryid,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("categoryid->",result.data);
        this.categoryinfo = result.data
        this.categoryinfo_show = true;
        this.loading = false;
      }

      this.loading = false;
    },
    async getTasklist(year_month) {
      console.log(year_month);
      this.loading = true;
      let result = await this.$get("/api/customer/task/list", {
        params: {
          year_month: year_month,
          // language: this.language,
        }
      });

      if (result.status === true) {

        this.calendarData = result.data;
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async getwebinfo(type) {
      this.loading = true;
      let result = await this.$get("/api/customer/systemSet/list", {
        params: {
          type: type,
          // language: this.language,
        }
      });

      if (result.status === true) {
        if(type === "1"){
          this.webinfo.webinfos = result.data;
        } if(type === "3"){
          this.webinfo.Links = result.data;
        } if(type === "4"){
          this.webinfo.Cooperation = result.data;
        }

        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.btn-danger {
  background-color: #CE1126 !important;
  border-color: #CE1126 !important;
}

.btn-white {
  background-color: unset !important;
  border-color: #fff !important;
}

//.ant-carousel {
//  /deep/.slick-slide {
//    text-align: center;
//    height: 160px;
//    line-height: 160px;
//    background: #364d79;
//    overflow: hidden;
//    h3 {
//      color: #fff;
//    }
//  }
//}
.index_1 {
  max-width: 1920px !important;
  height: 680px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .carousel_info {
    width: 1248px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 450px;

    h3 {
      color: #fff;
      font-size: 38px;
      font-weight: bold;
    }

    h4 {
      color: #fff;
      font-size: 19px;
      margin-bottom: 20px;
    }

  }

  .carousel_0, .carousel_1, .carousel_2, .carousel_3, .carousel_4, .carousel_5.carousel_6, .carousel_7, .carousel_8, .carousel_9, .carousel_10 {
    //background-color: #1890ff;
    max-width: 1920px !important;
    height: 680px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top

  }
}

.index_2 {
  max-width: 1920px !important;
  height: 758px;
  margin-left: auto;
  margin-right: auto;

  .PMTinfo {
    max-width: 1248px;
    height: 378px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 64px;
    background-color: #F2F4F5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .infoleft {
      padding: 20px 38px;
      width: 50%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;

      h3 {
        font-weight: bolder;
        font-size: 24px;
      }

      .info {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 32px;
        text-indent:2em;
        text-align: justify;
      }
    }

    .inforight {
      width: 600px;
      //background-color: #573e51;
      background-repeat: no-repeat;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .getCertificate {
    width: 1248px;
    margin-top: 64px;
    background-color: #F7F9FA;
    margin-right: auto;
    margin-left: auto;
    height: 188px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E6EAEB;
    background-image: url("~@/static/images/search-bg.png") !important;

    .top {
      width: 192px;
      height: 8px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      justify-content: space-between;

      span {
        width: 64px;
        height: 8px;
      }

      span:first-child {
        background-color: #002654;
      }

      span:last-child {
        background-color: #CE1126;
      }
    }

    h3 {
      color: #000;
      font-size: 24px;
      margin-top: 23px;
      font-weight: bolder;
      text-align: center;
    }

    .Certificate {
      text-align: center;
      width: 720px;
      margin-right: auto;
      margin-left: auto;
    }

    .SearchCertificate {
      width: 520px;
      height: 44px;
      background: #FFFFFF;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      margin-right: 10px;
      //border: 1px solid #E0E4E5;
      /deep/ .ant-input {
        border: 1px solid #E0E4E5 !important;
      }

      .cx {
        margin-left: 15px
      }
    }

  }

}

.mores {
  text-align: center;
  width: 100%
}

.index_3 {
  max-width: 1920px !important;
  height: 790px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 140px;
  background-image: url("~@/static/images/calendar-bg.png") !important;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover !important;

  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 45px;
  }

  .calendar {
    max-width: 1248px;
    height: 382px;
    margin-right: auto;
    margin-left: auto;

    .calendar_list {

      display: flex;
      justify-content: flex-start;
      margin-bottom: 40px;

      .calendar_info {
        background: #FFFFFF;
        border-radius: 8px;
        width: 50%;
        height: 382px;
        display: flex;
        margin-right: 32px;
        justify-content: flex-start;

        .info_left {
          width: 100%;
        }

        .info_right {
          width: 100%;
          background: #F7F9FA;
          padding: 12px 21px;
          border-radius: 8px;

          h3 {
            font-size: 16px;
            font-weight: bold;
          }

          .cardslist2 {
            width: 100%;
          }
        }
      }
    }
  }
}

/deep/ .ant-fullcalendar-selected-day .ant-fullcalendar-value, .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  //color: #fff;
  //background: #1890ff;
  border: 1px solid #CE1126 !important;
  width: 40px;
  height: 40px;
  color: #CE1126;
  font-weight: bold;
  background-color: transparent !important;
  border-radius: 50% !important;
}

/deep/ .ant-fullcalendar-today .ant-fullcalendar-value, .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: unset !important;
}

/deep/ .ant-badge-status-dot {
  top: -13px !important;
}

/deep/ .ant-fullcalendar-calendar-body {
  padding: 8px 24px;
}

/deep/ .ant-fullcalendar-value {
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 0px;
  padding-top: 2px;
  padding-left: 2px;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}

.calenderdate {


  /deep/ .ant-radio-group {

    display: none;

  }


}

/deep/ .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: bolder !important;
  margin-top: 17px;
  margin-bottom: 17px;
}

.cards {
  width: 288px;
  height: 382px;
  margin-right: 32px;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0px;
  }

  .del {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .tags {
    padding: 2px 8px;
    font-size: 16px;
  }

  .address {
    width: 100%;
    height: 40px;
    display: flex;
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 18px;
    min-height: 96px;
  }

  .work_mode {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    .lefttime {
      border-right: 1px solid #000
    }

    .righttime {
      margin-left: 20px;
    }

    .times {
      width: 49%;
      min-height: 50px;
    }

    /deep/ .ant-btn-danger {
      background: #CE1126 !important;
      border: 1px solid #CE1126 !important;
    }
  }
}

.cardsone {
  width: 608px;
  height: 382px;
  margin-right: 32px;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0px;
  }

  .del {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .tags {
    padding: 2px 8px;
    font-size: 16px;
  }

  .address {
    width: 100%;
    height: 40px;
    display: flex;
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 18px;
    min-height: 96px;
  }

  .work_mode {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    .lefttime {
      border-right: 1px solid #000
    }

    .righttime {
      margin-left: 20px;
    }

    .times {
      width: 49%;
      min-height: 50px;
    }

    /deep/ .ant-btn-danger {
      background: #CE1126 !important;
      border: 1px solid #CE1126 !important;
    }
  }
}

.cardlist {
  width: 608px;
  display: flex;
  justify-content: space-between;
  .newslist{
    width: 48%;
  }

}

.cardslist2 {
  border-radius: 8px;
  border: 1px solid #E6EAEB;
  height: 90px;
  padding: 12px;
  margin-bottom: 8px;
  background-color: #FFFFFF;

  .list_title {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 14px;
    margin-top: 8px
  }
}

.index_4 {
  max-width: 1920px !important;
  height: 710px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  background-image: url("~@/static/images/news-bg.png") !important;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 45px;
  }

  .news {
    max-width: 1248px;
    height: 382px;
    margin-right: auto;
    margin-left: auto;
  }
}

.index_5 {
  max-width: 1920px !important;
  height: 610px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;

  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 45px;
  }

  .news {
    max-width: 1248px;
    height: 382px;
    margin-right: auto;
    margin-left: auto;
  }
}

.index_6 {
  max-width: 1920px !important;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  background-color: #ffffff;

  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    line-height: 45px;
  }

  .links {
    max-width: 1248px;
    min-height: 150px;
    margin-right: auto;
    margin-left: auto;
    .links_list{
      display: flex;
      justify-content:space-between;
      flex-direction: row;

      flex-wrap: wrap;
    }
  }
}.index_7 {
  max-width: 1920px !important;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
   background-image: url("~@/static/images/email-bg.png") !important;
   background-size: cover;
   background-position: center top;
   background-repeat: no-repeat;

  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    line-height: 45px;
  }

  .newsletter {
    max-width: 1248px;
    min-height: 150px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
  }
  .newsletterinfo{
    font-size: 26px;
    color: #fff;
    font-weight: bold;
  }
  .newsletterleft{
    padding-top: 97px;
  }
   .newsletterright{
    padding-top: 120px;
    display: flex;
    justify-content:flex-start;
     .Subinputer{
      width: 360px;
       height: 48px;
       border-radius:24px;
       padding: 10px 30px;
    }
     .Subbut{
       height: 48px;
       border: 2px solid #fff;
       background: transparent;
       border-radius:24px;
       color: #fff;
       padding: 10px 30px;
       margin-left: 20px;
     }
  }
}
 .sitelinks{
   width: 180px;
   height: 80px;
   display: block;
   margin-bottom: 20px;
   background-repeat: no-repeat;
   background-size:contain;
   background-position: top center;
   border: 1px solid #eee;
   padding: 1px;
   border-radius: 10px;
   cursor: pointer;
 }
 .media_list{
   position: fixed;
   right: 50px;
   top: 80%;
   width: 45px;
   cursor: pointer;
   z-index: 1000;
   .medialinks{
     width: 45px;
     height: 45px;
     display: block;
     background-repeat: no-repeat;
     background-size:contain;
     background-position: center center;
     border: 1px solid #f00;
     padding: 1px;
     margin-bottom: 10px;
     border-radius: 24px;
     line-height: 15px;
   }
   .gohome{
     cursor: pointer;
     text-align: center;
     padding-top: 5px;
     background-color: #f00;
     color: #fff;
   }
 }
</style>